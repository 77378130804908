import { Container } from "./Container";

export function Services() {
  return (
    <div className="relative bg-white py-20 sm:pb-24 sm:pt-36">
      <Container className="relative">
        <div className="flex-1 mb-5">
          <h3 className="font-display text-3xl font-bold tracking-tighter text-blue-600 sm:text-7xl">Services</h3>
        </div>
        <div className="mx-auto flex sm:flex-row flex-col">
          {services.map((service) => (
            <div key={service.name} className="flex flex-col items-center justify-center p-6 bg-gray-100 rounded-lg shadow-lg sm:mr-4 sm:mb-0 mb-4">
              <div className="text-4xl">{service.icon}</div>
              <h2 className="text-2xl font-semibold mt-4">{service.name}</h2>
              <p className="text-center mt-2">{service.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

const services = [
  {
    name: "Web Development",
    description: "I build high-quality websites and web apps that are fast, secure, and easy to use.",
    icon: "💻",
  },
  {
    name: "UI/UX Design",
    description: "I design beautiful and intuitive user interfaces that provide a great user experience.",
    icon: "🎨",
  },
  {
    name: "Consulting",
    description: "I offer consulting services to help you plan, develop, and launch your digital projects.",
    icon: "🤝",
  },
];
