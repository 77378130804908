import backgroundImage from "../images/bren.JPG";
import { Container } from "./Container";

export function Me() {
  return (
    <div className="relative bg-white py-10 sm:py-16">
      <Container className="relative">
        <div className="flex-1 mb-5">
          <h3 className="font-display text-3xl font-bold tracking-tighter text-blue-600 sm:text-7xl">About Me</h3>
        </div>
        <div className="mx-auto flex sm:flex-row flex-col">
          <div className="w-full flex flex-col items-center justify-center">
            <img className="w-96 h-96 rounded-full p-3 bg-blue-600 shadow-lg" src={backgroundImage} alt="" />
            <h2 className="text-2xl font-semibold mt-4">Brendan O'Neill</h2>
            <p className="text-center mt-2 max-w-lg text-xl text-gray-500">
              I am a software engineer based in Dublin, Ireland. I have a passion for building high-quality software and web applications that help companies and entrepreneurs bring their ideas to
              life
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
