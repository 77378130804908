import "./App.css";
import { CTA } from "./components/CTA";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Me } from "./components/Me";
import { Services } from "./components/Services";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Services />
      <Me />
      <CTA />
      <Footer />
    </div>
  );
}

export default App;
